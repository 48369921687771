<template>
  <!-- If don't saved -->
  <ModalEmpty
    v-if="isOpen"
    @close="
      () => {
        isOpen = false
        leaveRoute = ''
      }
    "
  >
    <div class="w-400 p-30 flex ai-c jc-c fd-c ta-center">
      <h1 class="title">Не сохранили изменения</h1>
      <div class="t-grey-dark fs-16 mt-5 mb-30">
        Ваши изменения не вступят в силу если их не сохранить, все равно хотите уйти?
      </div>

      <div class="flex ai-c jc-sb ggap-20">
        <button class="btn cancel" @click="leaveAction(false)">Не сохранять и уйти</button>
        <button class="btn green" @click="leaveAction(true)">Сохранить и уйти</button>
      </div>
    </div>
  </ModalEmpty>

  <div class="notifications pos-r z1 pt-20 pb-20">
    <BaseLoad v-if="isLoad" class="pos-a grid jc-c z99999 white-transparent" />
    <h3 class="title mb-20">Настройки уведомлений</h3>
    <div class="box notifications__rows">
      <button
        type="button"
        class="notifications__row grid gtc-1-auto ai-c jc-sb pl-20 pr-20 pt-10 pb-10 w-100 active"
        @click="toggleAll()"
      >
        <div>Включить все уведомления</div>
        <FormFakeSwitch :isTrue="isToggleAll" />
      </button>
    </div>

    <!-- When -->
    <div class="box mt-20 notifications__rows">
      <h3 class="title pl-20 pr-20 pt-10 pb-10">Когда уведомлять:</h3>
      <button
        type="button"
        class="notifications__row grid gtc-1-auto ai-c jc-sb pl-20 pr-20 pt-10 pb-10 w-100"
        :class="{ active: formData.changing_status }"
        @click="formData.changing_status = !formData.changing_status"
      >
        <div>Уведомлять при смене статуса</div>
        <FormFakeSwitch :isTrue="formData.changing_status" />
      </button>
      <button
        type="button"
        class="notifications__row grid gtc-1-auto ai-c jc-sb pl-20 pr-20 pt-10 pb-10 w-100"
        :class="{ active: formData.is_commentary }"
        @click="formData.is_commentary = !formData.is_commentary"
      >
        <div>Уведомлять если написали сообщение</div>
        <FormFakeSwitch :isTrue="formData.is_commentary" />
      </button>
    </div>

    <!-- Where -->
    <div class="box mt-20 notifications__rows">
      <h3 class="title pl-20 pr-20 pt-10 pb-10">Где уведомлять:</h3>
      <button
        type="button"
        class="notifications__row grid gtc-1-auto ggap-20 ai-c jc-sb pl-20 pr-20 pt-10 pb-10 w-100"
        :class="{ active: formData.is_telegram }"
        @click="formData.is_telegram = !formData.is_telegram"
      >
        <div class="grid gtc-2 ai-c ggap-10">
          <span>Уведомлять в телеграм:</span>
          <b>{{ userTelegram ? userTelegram : 'Не указан' }}</b>
        </div>
        <FormFakeSwitch :isTrue="formData.is_telegram" />
      </button>
      <button
        type="button"
        class="notifications__row grid gtc-1-auto ggap-20 ai-c jc-sb pl-20 pr-20 pt-10 pb-10 w-100"
        :class="{ active: formData.is_email }"
        @click="formData.is_email = !formData.is_email"
      >
        <div class="grid gtc-2 ai-c ggap-10">
          <span>Уведомлять на e-mail:</span>
          <b>{{ userEmail ? userEmail : 'Не указан' }}</b>
        </div>
        <FormFakeSwitch :isTrue="formData.is_email" />
      </button>
    </div>

    <div class="flex ai-c jc-sb ggap-20 mt-20">
      <button class="btn cancel" @click.prevent="updateSettings(true)" :disabled="isNoChanged">
        Сбросить изменения
      </button>
      <button class="btn primary" @click.prevent="updateSettings(false)" :disabled="isNoChanged">
        Сохранить изменения
      </button>
    </div>
  </div>
</template>

<script setup>
import { cloneDeep } from 'lodash'
import { useStore } from 'vuex'
import { onBeforeRouteLeave, useRouter } from 'vue-router'
import { defineEmits, ref, computed, watchEffect } from 'vue'
import { BaseLoad, ModalEmpty, FormFakeSwitch } from '@/components'

// Emits
const emits = defineEmits(['loadAction'])

// Data
const router = useRouter()
const store = useStore()
const isLoad = ref(false)
const isOpen = ref(false)
const leaveRoute = ref('')
const isToggleAll = ref(true)
const formData = ref({})

// Computed
const settings = computed(() => store.getters['notifications/settings'])
const isNoChanged = computed(() => {
  const res = []
  for (const key in formData.value) {
    if (Object.hasOwnProperty.call(formData.value, key)) {
      const element = formData.value[key]
      res.push(element === settings.value[key])
    }
  }
  return res.every((el) => el)
})

// Computed:user
const user = computed(() => store.getters?.profile)
const userTelegram = computed(() => {
  let res = ''
  if (user.value?.contacts?.length) {
    res = user.value.contacts.find((el) => el.type === 'telegram')
  }
  return res ? res.value : ''
})
const userEmail = computed(() => {
  let res = ''
  if (user.value?.contacts?.length) {
    res = user.value.contacts.find((el) => el.type === 'email')
  }
  return res ? res.value : ''
})

// Watch
watchEffect(() => {
  isToggleAll.value = Object.values(formData.value).every((elem) => elem === true)
})

// Created
getSettings()

// Router before leave
onBeforeRouteLeave((to, from, next) => {
  if (!isNoChanged.value && !leaveRoute.value) {
    isOpen.value = true
    leaveRoute.value = to.fullPath
  } else {
    next()
  }
})

// Methods
function toggleAll() {
  isToggleAll.value = !isToggleAll.value
  for (const key in formData.value) {
    if (Object.hasOwnProperty.call(formData.value, key)) {
      formData.value[key] = isToggleAll.value
    }
  }
}
async function getSettings() {
  try {
    emits('loadAction', true)
    await store.dispatch('notifications/GET_SETTINGS')
    formData.value = cloneDeep(settings.value)
    emits('loadAction', false)
  } catch (error) {
    console.log(error)
  }
}
async function updateSettings(isReset = false) {
  try {
    emits('loadAction', true)
    await store.dispatch('notifications/UPDATE_SETTINGS', { payload: formData.value, isReset })
    await getSettings()
    emits('loadAction', false)
  } catch (error) {
    console.log(error)
  }
}
async function leaveAction(isSave = false) {
  isOpen.value = false
  if (!isSave) return router.push(leaveRoute.value)

  try {
    await updateSettings(false)
    router.push(leaveRoute.value)
  } catch (error) {
    console.log(error)
  }

  leaveRoute.value = ''
}
</script>

<style lang="scss">
.notifications {
  &__rows {
    overflow: hidden;
  }

  &__row {
    text-align: left;
    background-color: transparent;
    border: 0;
    border-top: var(--b1);
    color: #999;
    cursor: pointer;

    &:hover {
      background-color: var(--grey-l);
      color: var(--text);
    }
    &.active {
      color: var(--text);
    }
    &:first-child {
      border: 0;
    }
  }
}

.dark {
  .notifications {
    &__rows {
      .title {
        color: #999;
      }
    }

    &__row {
      color: #777;

      &:hover {
        background-color: var(--grey-l);
        color: var(--text);
      }
      &.active {
        color: var(--text);
      }
    }
  }
}
</style>
